<template>
  <div>
    <h1 class="heading-txt mb-3">Update Bank Details</h1>
    <SectionLoading v-if="loading" text="Bank Settings loading..." />
    <div v-else class="row">
      <div class="col-md-5">
        <div class="card siteCard mt-3">
          <div class="p-3">
            <form role="form" @submit.prevent="initiateUpdate">
              <div class="form-group">
                <label class="text-dark" to="gender">Bank</label>
                <select v-model="bank.name" name="gender" class="form-control" id="gender">
                  <option v-for="(bank, index) in banks" :key="index" :value="bank.code">
                    {{ bank.name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label class="text-dark" to="account">Account Number</label>
                <input v-model="bank.number" required type="number" maxlength="10" name="account" id="account"
                  class="form-control" />
              </div>
              <div v-if="validated">
                <vs-alert color="dark">
                  <span>
                    <b>{{ bankValidate.account_name }}</b> |
                    {{ bankValidate.account_number }}
                  </span>
                </vs-alert>
              </div>

              <button type="submit" :disabled="validating || loading || !validated || updating"
                class="btn btn-primary btn-site px-5 mt-4">
                <span v-if="validating"> Validating Account</span>
                <span v-else> Save Bank details</span>
                <BtnLoading v-if="loading || validating || updating" class="d-inline-block" height="18" />
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <vs-popup title="Verify OTP" :active.sync="verifyOtpModal">
      <OTPVerify @onSuccess="verifySuccess" @onError="verifyError" />
    </vs-popup>
  </div>
</template>
<script>
import BtnLoading from "@/components/BtnLoading.vue";
import SectionLoading from "@/components/SectionLoading";
import OTPVerify from "@/components/OTPVerify.vue";


export default {
  data() {
    return {
      banks: [],
      loading: false,
      validating: false,
      validated: false,
      updating: false,
      verifyOtpModal: false,
      bank: {
        name: "",
        number: "",
      },
      bankValidate: {},
    };
  },
  computed: {
    user() {
      return this.$store.getters.user || {};
    },
  },
  components: {
    SectionLoading,
    BtnLoading,
    OTPVerify
  },
  mounted() {
    this.getBankSettings();
  },

  watch: {
    "bank.number": {
      handler: function (val, oldVal) {
        if (val.length === 10) {
          this.validateBankNumber();
        }
      },
    },
    "bank.name": {
      handler: function (val, oldVal) {
        if (val) {
          this.validateBankNumber();
        }
      },
    },
  },
  methods: {
    resetUser() {
      let fetch = {
        path: `/profile`,
      };

      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          let { user } = resp.data.data;
          user = this.CryptoJS.AES.encrypt(
            JSON.stringify(user),
            this.$passPhrase
          ).toString();

          localStorage.setItem("user", user);
        })
        .catch((err) => { });
    },
    initiateUpdate() {
      const data = {
        email: this.user ? this.user.email : '',
      };
      let fetch = {
        path: `auth/account/resend-otp`,
        data,
      };
      this.$store
        .dispatch("postRequest", fetch)
        .then((resp) => {
        })
        .catch((err) => { })

      this.verifyOtpModal = true;
    },
    updateBankAccount() {
      this.updating = true;
      const bank = this.banks.find((bank) => bank.code === this.bank.name);

      const data = {
        bank_name: bank.name,
        bank_code: bank.code,
        account_name: this.bankValidate.account_name,
        account_number: this.bankValidate.account_number,
      };

      let fetch = {
        path: `/profile/update/bank`,
        data,
      };

      this.$store
        .dispatch("postRequest", fetch)
        .then((resp) => {
          const { data } = resp.data;

          this.updating = false;
          this.resetUser();

          this.$toast.success(
            "Bank Update",
            "Bank Account details updated successfully",
            this.$toastPosition
          );
        })
        .catch((err) => {
          this.updating = false;
          if (err.response) {
            this.$toast.info(
              "Bank Update",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Bank Update",
              "Unable to update bank account, check the details and try again",
              this.$toastPosition
            );
          }
        });
    },
    validateBankNumber() {
      if (this.bank.number && this.bank.name) {
        this.validating = true;

        let fetch = {
          path: `/profile/update/bank/resolve`,
          data: {
            account_number: this.bank.number,
            bank_code: this.bank.name,
          },
        };

        this.$store
          .dispatch("postRequest", fetch)
          .then((resp) => {
            const { data } = resp.data;

            this.validating = false;
            if (!data.status) {
              this.$toast.info(
                "Bank Validation",
                data.message,
                this.$toastPosition
              );
            } else {
              this.validated = true;
              this.bankValidate = data.data;
            }
          })
          .catch((err) => {
            this.validating = false;
            if (err.response) {
              this.$toast.info(
                "Bank Update",
                err.response.data.message,
                this.$toastPosition
              );
            } else {
              this.$toast.info(
                "Bank Update",
                "Unable to validate account, check the details and try again",
                this.$toastPosition
              );
            }
          });
      }
    },
    getBankSettings() {
      let fetch = {
        path: `https://api.paystack.co/bank`,
      };
      this.loading = true;
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          const { data } = resp.data;
          if (this.user.bank) {
            this.bank.name = this.user.bank.bank_code;
            this.bank.number = this.user.bank.account_number;
          }

          this.banks = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.$vs.notify({
              title: "Error",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "All Banks",
              text: "Unable to get Banks",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        });
    },
    verifySuccess() {
      this.verifyOtpModal = false;
      this.updateBankAccount();
    },
    verifyError(err) {
      console.log('On Error')
      if (err.response) {
        this.$toast.info(
          "OTP Verify",
          err.response.data.message,
          this.$toastPosition
        );
      } else {
        this.$toast.info(
          "OTP Verify",
          "Something went wrong, please try again",
          this.$toastPosition
        );
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.heading-txt {
  font-size: 3em;
  line-height: 1;
  font-weight: 700;
  color: #000;
}

.sect-data {
  border-bottom: 1px solid gainsboro;
  margin-bottom: 13px;
}

.active-plan {
  color: #000;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 2px;
}
</style>
